/*=========================================================================================
  File Name: moduleDepartmentMutations.js
  Description: Department Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Departments.unshift(item)
  },
  SET_Department(state, Departments) {
    state.Departments = Departments
  },
  UPDATE_Department(state, Department) {
    const DepartmentIndex = state.Departments.findIndex((p) => p.ID == Department.ID)
    Object.assign(state.Departments[DepartmentIndex], Department)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Departments.findIndex((p) => p.ID == itemId)
    state.Departments.splice(ItemIndex, 1)
},
}
